import React from 'react';
import { Typography } from '@mui/material';
import KursAnlegen from './reusable_modules/KursAnlegen'; // Importiere das Kurs-Anlegen-Modul
import './DozentInPage.css'; // Falls du spezifische Styles für die DozentIn-Seite brauchst
import DozentInHinzufuegen from './reusable_modules/DozentInHinzufuegen';
import DozentInEntfernen from './reusable_modules/DozentInEntfernen';
import KursEntfernen from './reusable_modules/KursEntfernen';
import UserEntfernen from './reusable_modules/UserEntfernen';
import DozentIn2Kurs from './reusable_modules/DozentIn2Kurs';
import DozentIn2KursEntfernen from './reusable_modules/DozentIn2KursEntfernen';
import KurseListe from './reusable_modules/KurseListe';
import ProfilVerwalten from './reusable_modules/ProfilVerwalten';
import Nachrichten from './reusable_modules/Nachrichten';

function DozentInPage() {
  return (
    <div className="DozentIn-container">
      <div className="DozentIn-header" style={{ padding: '64px 0', textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Verwaltungsbereich
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Verwalten Sie Ihre Kurse und Inhalte.
        </Typography>
      </div>

      <div className="DozentIn-list">
        <KurseListe />
        <Nachrichten /> 
        <ProfilVerwalten />
        
      </div>
    </div>
  );
}

export default DozentInPage;
