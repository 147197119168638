import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import CourseNavigation from '../CourseNavigation'; // Sidebar importieren
import Termine from '../Termine'; // Optional: Termine importieren
import AuthContext from '../AuthContext';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'; // Import Chevron Icons
import './KapitelPage.css'; // Importiere die CSS-Datei
import { QuizComponent } from './Quiz';

function KapitelPage() {
  const { kapitelId } = useParams(); // Get the chapter ID from the URL
  const [kapitel, setKapitel] = useState({ name: '', text: '' }); // Chapter name and content
  const [error, setError] = useState(null); // Error state
  const { user } = useContext(AuthContext); // Access the user from AuthContext
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar state
  const navigate = useNavigate(); // For navigating to the edit page

  // Fetch chapter data based on kapitelId
  useEffect(() => {
    if (kapitelId) {
      axios
        .get(`/api/singleKapitel/${kapitelId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          const { name, text } = response.data;
          setKapitel({ name, text });
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen des Kapitels:', error);
          setError('Kapitel oder Inhalt nicht gefunden.');
        });
    } else {
      setError('Keine Kapitel-ID vorhanden.');
    }
  }, [kapitelId, user.token]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    const mainContent = document.querySelector('.main-content');
    if (sidebarOpen) {
      mainContent.classList.remove('shifted');
    } else {
      mainContent.classList.add('shifted');
    }
  };

  // Update renderContent function:
  const renderContent = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = kapitel.text;
    
    // Split content by quiz elements
    const parts = [];
    const quizElements = tempDiv.getElementsByClassName('quiz');
    let currentPosition = 0;
  
    Array.from(quizElements).forEach((quiz, index) => {
      try {
        // Get text before quiz
        const startIndex = kapitel.text.indexOf(quiz.outerHTML, currentPosition);
        if (startIndex > currentPosition) {
          parts.push(
            <div key={`text-${index}`} 
                 dangerouslySetInnerHTML={{ 
                   __html: kapitel.text.substring(currentPosition, startIndex) 
                 }} 
            />
          );
        }
  
        // Add quiz
        const quizData = JSON.parse(quiz.dataset.quiz);
        parts.push(<QuizComponent key={`quiz-${index}`} data={quizData} />);
        
        currentPosition = startIndex + quiz.outerHTML.length;
      } catch (err) {
        console.error('Error parsing quiz:', err);
      }
    });
  
    // Add remaining text
    if (currentPosition < kapitel.text.length) {
      parts.push(
        <div key="text-final" 
             dangerouslySetInnerHTML={{ 
               __html: kapitel.text.substring(currentPosition) 
             }} 
        />
      );
    }
  
    return parts;
  };

  if (error) {
    return <div>{error}</div>; // Display error if something goes wrong
  }

  if (!kapitel.name) return <div>Lade...</div>;

  return (
    <div id="container" className={sidebarOpen ? 'sidebar-open' : ''}>
      <button className={`toggle-button ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </button>
      <nav>
        {/* Sidebar für Kurse und Kapitel */}
        <CourseNavigation sidebarOpen={sidebarOpen} />
      </nav>
      <main className={`main-content ${sidebarOpen ? 'shifted' : ''}`}>
        <Container>
          <div className="kapitel-header">
            <Typography variant="h2" component="h1" gutterBottom>
              {kapitel.name}
            </Typography>
            <Typography variant="h5" component="p" gutterBottom>
              Kapitelinhalt
            </Typography>
          </div>

          {/* Chapter Text */}
          <Box
            className="kapitel-content"
            sx={{
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: 'var(--accent-color)',
              '& img, & video, & iframe': {
                maxWidth: '100%',
                height: 'auto',
                display: 'block',
                margin: '1em auto'
              }
            }}
          >
            {renderContent()}
          </Box>
        </Container>
      </main>

      {/* Optional: Termine oder andere Komponenten */}
      <aside id="kapitel-termine">
        <Termine />
      </aside>
    </div>
  );
}

export default KapitelPage;