import React from 'react';
import { Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ProfilVerwalten() {

  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Button
        onClick={() => navigate(`/profile`)}
        variant="contained"
        fullWidth
        style={{ 
          marginBottom: '10px', 
          minHeight: '48px', 
          backgroundColor: 'var(--primary-color)',  // Hintergrundfarbe
          color: 'var(--custom-white)',              // Schriftfarbe
          fontWeight: 'bold',                      // Schriftgewicht
          textAlign: 'left',                       // Text linksbündig
          justifyContent: 'flex-start',            // Inhalt linksbündig
          paddingLeft: '16px'                      // Padding links
        }}
      >
        Profil verwalten
      </Button>
    </Container>
  );
}

export default ProfilVerwalten;
