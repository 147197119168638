import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Hinzufügen von axios für HTTP-Anfragen
import './Kontakt.css';

function Kontakt() {
  const [formData, setFormData] = useState({
    vorname: '',
    nachname: '',
    email: '',
    nachricht: ''
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSend = async () => {
    try {
      // Senden der Daten an das Backend
      await axios.post('/api/kontakt', formData);
      setOpen(true); // Erfolgsmeldung
    } catch (error) {
      console.error('Fehler beim Senden der Nachricht:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/');
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="lg">
      <Box py={10} textAlign="center">
        <Typography variant="h2" component="h1" gutterBottom>
          Kontaktieren Sie uns
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Wir freuen uns auf Ihre Fragen und Anregungen.
        </Typography>
      </Box>

      <Box className="kontakt-section">
        <form className="kontakt-form">
          <TextField
            label="Vorname"
            name="vorname"
            variant="outlined"
            fullWidth
            required
            value={formData.vorname}
            onChange={handleInputChange}
          />
          <TextField
            label="Nachname"
            name="nachname"
            variant="outlined"
            fullWidth
            required
            value={formData.nachname}
            onChange={handleInputChange}
          />
          <TextField
            label="E-Mail-Adresse"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            required
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            label="Nachricht"
            name="nachricht"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={6}
            value={formData.nachricht}
            onChange={handleInputChange}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              className="send-button"
            >
              Senden
            </Button>
            <Button
              variant="contained"
              onClick={handleCancel}
              className="cancel-button"
            >
              Abbrechen
            </Button>
          </Box>
        </form>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nachricht gesendet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ihre Nachricht wurde erfolgreich versendet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Kontakt;