import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import EditIcon from '@mui/icons-material/Edit';

function KapitelFormular() {
  const { kapitelId } = useParams();  // kapitelId aus den Routen-Parametern holen
  const [kapitelName, setKapitelName] = useState('');
  const [inhalt, setInhalt] = useState(''); // Der Textinhalt des Kapitels
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [editNameDialog, setEditNameDialog] = useState(false);
  const [newKapitelName, setNewKapitelName] = useState('');

  useEffect(() => {
    if (kapitelId) {
      // Kapitel und KapitelText abrufen
      axios.get(`/api/singleKapitel/${kapitelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { name, text } = response.data;
        setKapitelName(name);  // Setze den Kapitelnamen
        setInhalt(text || ''); // Setze den Textinhalt
        setLoading(false);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen des Kapitels:', error);
        setLoading(false);
      });
    }
  }, [kapitelId, token]);
  
  

  const handleEditorChange = (content) => {
    setInhalt(content); // Inhalt des Editors setzen
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(`/api/updateKapitelText/${kapitelId}`, {
        text: inhalt, // Textinhalt aus dem Editor
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage(response.data.message);
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Speichern des Kapitels:', error);
      setMessage('Ein Fehler ist aufgetreten');
      setOpenDialog(true);
    }
  };
  
  const handleUpdateName = async () => {
    try {
      const response = await axios.put(`/api/updateKapitelName/${kapitelId}`, {
        name: newKapitelName
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setKapitelName(newKapitelName);
      setEditNameDialog(false);
      setMessage('Kapitelname erfolgreich aktualisiert');
      setOpenDialog(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Kapitelnamens:', error);
      setMessage('Fehler beim Aktualisieren des Kapitelnamens');
      setOpenDialog(true);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="md">
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
          {kapitelName} bearbeiten
        </Typography>
        <IconButton onClick={() => {
          setNewKapitelName(kapitelName);
          setEditNameDialog(true);
        }}>
          <EditIcon />
        </IconButton>
      </div>

      <Dialog open={editNameDialog} onClose={() => setEditNameDialog(false)}>
        <DialogTitle>Kapitelnamen bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Kapitelname"
            fullWidth
            value={newKapitelName}
            onChange={(e) => setNewKapitelName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNameDialog(false)}>Abbrechen</Button>
          <Button onClick={handleUpdateName}>Speichern</Button>
        </DialogActions>
      </Dialog>

      <Editor
  tinymceScriptSrc='/tinymce/tinymce.min.js'
  value={inhalt}
  init={{
    height: 1000,
    menubar: false,
    license_key: 'gpl',
    plugins: [
      'link', 'lists', 'image', 'media', 'quickbars', 'advlist', 'table', 'code'
    ],
    toolbar: 
      'undo redo | formatselect | ' +
      'fontfamily fontsize | ' +
      'bold italic | forecolor backcolor | ' +
      'alignleft aligncenter alignright alignjustify | ' +
      'bullist numlist outdent indent | ' +
      'removeformat | help | image media | quiz',
    font_formats: [
      'Arial=arial,helvetica,sans-serif',
      'Arial Black=arial black,avant garde',
      'Book Antiqua=book antiqua,palatino',
      'Comic Sans MS=comic sans ms,sans-serif',
      'Courier New=courier new,courier',
      'Georgia=georgia,palatino',
      'Helvetica=helvetica',
      'Impact=impact,chicago',
      'Symbol=symbol',
      'Tahoma=tahoma,arial,helvetica,sans-serif',
      'Times New Roman=times new roman,times',
      'Verdana=verdana,geneva'
    ].join(';'),
    fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
    quickbars_insert_toolbar: 'quickimage quicktable | hr',
    quickbars_image_toolbar: 'alignleft aligncenter alignright | imageoptions',
    images_upload_url: '/api/upload-image',
    automatic_uploads: true,

    // Bild-Upload-Handler
    images_upload_handler: (blobInfo, success, failureCallback) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob());
      axios.post('/api/upload-image', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        success(response.data.location);
      })
      .catch(() => {
        failureCallback('Bild konnte nicht hochgeladen werden.');
      });
    },

    // Video- und Audio-Upload-Handler
    file_picker_types: 'image media',
    file_picker_callback: function (callback, value, meta) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (meta.filetype === 'image') {
        input.setAttribute('accept', 'image/*');
      } else {
        input.setAttribute('accept', 'audio/*,video/*');
      }
      input.onchange = function () {
        const file = this.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post(
          file.type.startsWith('audio') ? '/api/upload-audio' : '/api/upload-video',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(response => {
          callback(response.data.location, { title: file.name });
        })
        .catch(() => {
          alert('Datei konnte nicht hochgeladen werden.');
        });
      };
      input.click();
    },

    media_live_embeds: true,
    media_dimensions: false,
    media_alt_source: false,
    media_poster: true,
    content_style: `
      img, video, iframe { 
        max-width: 100% !important; 
        height: auto !important;
        display: block !important;
        margin: 1em auto !important;
      }
    `,
    setup: (editor) => {
      // Add createQuizHtml function
      const createQuizHtml = (quizData) => `
        <div class="quiz-wrapper mceNonEditable" contenteditable="false">
          <div class="quiz" data-quiz='${JSON.stringify(quizData)}'>
            <div class="quiz-editor-preview">
              <div class="quiz-delete">✕</div>
              <div class="quiz-header">Quiz</div>
              <h3>${quizData.question}</h3>
              <ul>
                ${quizData.answers.map(a => 
                  `<li>${a.text}${a.correct ? ' ✓' : ''}</li>`
                ).join('')}
              </ul>
              <button class="edit-quiz">Bearbeiten</button>
            </div>
          </div>
        </div>
      `;

      const handleQuizDialog = function(existingData = null, isEditing = false) {
        let currentAnswers = existingData ? existingData.answers : [{ text: '', correct: false }];
        let currentQuizData = existingData || {
          type: 'single',
          question: '',
          answers: [{ text: '', correct: false }]
        };
      
        const dialog = editor.windowManager.open({
          title: isEditing ? 'Quiz bearbeiten' : 'Quiz erstellen',
          size: 'large',
          body: {
            type: 'panel',
            items: [
              {
                type: 'selectbox',
                name: 'type',
                label: 'Quiz-Typ',
                items: [
                  { value: 'single', text: 'Single Choice' },
                  { value: 'multiple', text: 'Multiple Choice' }
                ]
              },
              {
                type: 'input',
                name: 'question',
                label: 'Frage'
              },
              ...currentAnswers.map((answer, index) => ({
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'input',
                    name: `answer${index}`,
                    label: `Antwort ${index + 1}`
                  },
                  {
                    type: 'checkbox',
                    name: `correct${index}`,
                    label: 'Korrekt'
                  }
                ]
              })),
              {
                type: 'button',
                name: 'addAnswer',
                text: 'Weitere Antwort'
              }
            ]
          },
          buttons: [
            { 
              type: 'submit', 
              text: isEditing ? 'Aktualisieren' : 'Erstellen'
            },
            { type: 'cancel', text: 'Abbrechen' }
          ],
          initialData: {
            type: currentQuizData.type,
            question: currentQuizData.question,
            ...currentAnswers.reduce((acc, answer, i) => ({
              ...acc,
              [`answer${i}`]: answer.text || '',
              [`correct${i}`]: answer.correct || false
            }), {})
          },
          onAction: function(api, details) {
            if (details.name === 'addAnswer') {
              const formData = api.getData();
              currentAnswers = [
                ...currentAnswers.map((_, i) => ({
                  text: formData[`answer${i}`] || '',
                  correct: formData[`correct${i}`] || false
                })),
                { text: '', correct: false }
              ];
              currentQuizData = {
                type: formData.type,
                question: formData.question,
                answers: currentAnswers
              };
              dialog.close();
              handleQuizDialog(currentQuizData, isEditing); // Pass isEditing state
            }
          },
          onSubmit: function(api) {
            const formData = api.getData();
            const quizData = {
              type: formData.type,
              question: formData.question,
              answers: currentAnswers.map((_, i) => ({
                text: formData[`answer${i}`] || '',
                correct: formData[`correct${i}`] || false
              })).filter(a => a.text.trim() !== ''),
              correctAnswers: currentAnswers
                .map((_, i) => formData[`correct${i}`] ? i : -1)
                .filter(i => i !== -1)
            };
      
            // Store only data, preview is for editor only
            const quizHtml = createQuizHtml(quizData);
      
            if (isEditing) {
              const existingQuiz = editor.selection.getNode().closest('.quiz');
              if (existingQuiz) {
                editor.undoManager.transact(() => {
                  editor.selection.select(existingQuiz);
                  editor.selection.setContent(quizHtml);
                });
              }
            } else {
              editor.insertContent(quizHtml);
            }
            api.close();
          }
        });
      };
    
      // Register quiz button
      editor.ui.registry.addButton('quiz', {
        text: 'Quiz',
        onAction: function() {
          handleQuizDialog(null, false); // New quiz, not editing
        }
      });
    
      // Add click handler for edit button
      editor.on('click', (e) => {
        if (e.target.closest('.edit-quiz')) {
          const quizElement = e.target.closest('.quiz');
          if (!quizElement) return;
          
          try {
            const quizData = JSON.parse(quizElement.dataset.quiz);
            if (quizData) {
              editor.selection.select(quizElement);
              editor.nodeChanged();
              handleQuizDialog(quizData, true); // Existing quiz, is editing
            }
          } catch (err) {
            console.error('Error parsing quiz data:', err);
          }
        }
      });

      // Add delete handler
      editor.on('click', (e) => {
        if (e.target.closest('.quiz-delete')) {
          const wrapper = e.target.closest('.quiz-wrapper');
          if (wrapper) {
            editor.selection.select(wrapper);
            editor.selection.setContent('');
            editor.nodeChanged();
          }
        }
      });
    
      // Rest of setup code...
      editor.on('ObjectResized', (e) => {
        const { target } = e;
        target.style.width = target.style.width.replace(/px$/, '%');
      });
      
      editor.on('BeforeSetContent', (e) => {
        e.content = e.content.replace(/(width|height)="[^"]*"/g, '');
        
        // Ensure quiz elements are properly structured
        if (e.content.includes('quiz')) {
          const temp = document.createElement('div');
          temp.innerHTML = e.content;
          
          // Remove incomplete quiz elements
          temp.querySelectorAll('.quiz').forEach(quiz => {
            if (!quiz.dataset.quiz || !quiz.querySelector('.quiz-editor-preview')) {
              quiz.remove();
            }
          });
          
          temp.querySelectorAll('.quiz').forEach(quiz => {
            if (!quiz.closest('.quiz-wrapper')) {
              quiz.remove();
            }
          });
          
          e.content = temp.innerHTML;
        }
      });
      
      // Add handler for node removal
      editor.on('NodeChange', (e) => {
        if (e.element) {
          // Clean up orphaned quiz elements
          editor.getBody().querySelectorAll('.quiz').forEach(quiz => {
            if (!quiz.dataset.quiz || !quiz.querySelector('.quiz-editor-preview')) {
              quiz.remove();
            }
          });
        }
      });
      
      // Add custom CSS for quiz preview in editor
      editor.on('init', () => {
        editor.dom.addStyle(`
          .quiz-preview {
            background: #f8f9fa;
            border: 1px solid #dee2e6;
            border-radius: 4px;
            padding: 15px;
            margin: 10px 0;
          }
          .quiz-header {
            color: #6c757d;
            font-size: 0.875rem;
            margin-bottom: 8px;
          }
          .quiz-preview h3 {
            color: #212529;
            margin: 0 0 12px 0;
          }
          .quiz-preview ul {
            list-style: none;
            padding: 0;
            margin: 0 0 15px 0;
          }
          .quiz-preview li {
            padding: 6px 0;
            color: #495057;
          }
          .edit-quiz {
            background: #0d6efd;
            color: white;
            border: none;
            padding: 6px 12px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.875rem;
          }
          .edit-quiz:hover {
            background: #0b5ed7;
          }
          .quiz-wrapper {
            position: relative;
            margin: 1em 0;
            cursor: default;
          }
          
          .quiz-delete {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 20px;
            height: 20px;
            background: #dc3545;
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.2s;
            z-index: 1;
          }
          
          .quiz-wrapper:hover .quiz-delete {
            opacity: 1;
          }
          
          .quiz-editor-preview {
            pointer-events: none;
          }
          
          .quiz-editor-preview .edit-quiz {
            pointer-events: auto;
            cursor: pointer;
          }
        `);
      });
    }
  }}
  onEditorChange={handleEditorChange}
/>



      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginRight: '20px' }}>
          Speichern
        </Button>
        <Button onClick={() => navigate(`/kapitel/${kapitelId}`)} variant="contained" color="secondary">
          Kapitel ansehen
        </Button>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Info</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default KapitelFormular;
