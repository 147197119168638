import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Box } from '@mui/material';
import AuthContext from '../AuthContext';
import './KursTitelseite.css';

function KursTitelseite() {
  const { kursId } = useParams(); // Get the course ID or course name from the URL
  const [kurs, setKurs] = useState(null);
  const [titelseite, setTitelseite] = useState(null);
  const [error, setError] = useState(null); // Error state
  const { user } = useContext(AuthContext); // Access the user from AuthContext
  const navigate = useNavigate(); // For navigating to the edit page
  const [isAdminOrDozent, setIsAdminOrDozent] = useState(false);

  const isNumeric = (str) => {
    console.log("param: ", kursId);
    return !isNaN(str) && !isNaN(parseFloat(str));
  };
  // Fetch course title page (Titelseite) by kursId or kursname
  useEffect(() => {
    if (isNumeric(kursId)) {
      console.log("wrong");
      axios.get(`/api/kursTitelseite/${kursId}`)
      .then(response => {
        // Beide Werte aus der Antwort extrahieren
        const { kurs, titelseite } = response.data;
        
        // Separate Zuordnung in State-Variablen
        setKurs(kurs);
        setTitelseite(titelseite);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Titelseite:', error);
        setError('Titelseite nicht gefunden oder Kurs existiert noch nicht.');
      });
    } else if (kursId) {
      console.log("right");
      axios.get(`/api/Titelseite/${kursId}`)
      .then(response => {
        // Beide Werte aus der Antwort extrahieren
        const { kurs, titelseite } = response.data;
        
        // Separate Zuordnung in State-Variablen
        setKurs(kurs);
        setTitelseite(titelseite);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Titelseite:', error);
        setError('Titelseite nicht gefunden oder Kurs existiert noch nicht.');
      });
    } else {
      setError('Keine Kurs-ID oder Kursname vorhanden.');
    }
  }, [kursId]); // Depend on both kursId and kursname

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const [adminResponse, dozentResponse] = await Promise.all([
          axios.get('/api/checkAdmin'),
          axios.get('/api/checkDozentIn')
        ]);
        setIsAdminOrDozent(adminResponse.data.isAdmin || dozentResponse.data.isDozentIn);
      } catch (error) {
        console.error('Error checking user status:', error);
        setIsAdminOrDozent(false);
      }
    };
    checkUserStatus();
  }, []);

  if (!kursId) {
    return <div>Keine Kurs-ID oder Kursname vorhanden. Bitte wählen Sie einen Kurs.</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display error if something goes wrong
  }

  if (!kurs) return <div>Lade...</div>;

  // Helper function to check if the user can edit the course page
  const canEdit = () => {
    return user && (user.role === 'admin' || kurs.instructor_id === user.id);
  };

  return (
    <Container>
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          marginTop: '20px',
          fontWeight: 'bold',
          fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' }, // Responsive sizes
        }}
      >
        {kurs.name}
      </Typography>

      {/* Course Leader */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        Kursleitung: <span style={{ fontWeight: 'normal' }}>{titelseite.kursleiter}</span>
      </Typography>

      {/* Display the course image as a banner */}
      {titelseite.titelbild && (
        <Box
          component="img"
          src={`/api/get-image/${titelseite.titelbild}`}
          alt="Course Banner"
          sx={{
            width: '100%',
            maxHeight: '300px',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      )}

      {/* Introduction */}
      <Box 
        className="kurs-content"
        sx={{
          marginBottom: '20px',
          padding: '10px',
          backgroundColor: 'var(--accent-color)',
          '& img, & video, & iframe': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            margin: '1em auto'
          }
        }}
        dangerouslySetInnerHTML={{ __html: titelseite.einleitung }}
      />

      {/* Course Content */}
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '20px' }}>
        Kursinhalt
      </Typography>
      <Box 
        className="kurs-content"
        sx={{
          padding: '10px',
          backgroundColor: 'var(--accent-color)',
          '& img, & video, & iframe': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            margin: '1em auto'
          }
        }}
        dangerouslySetInnerHTML={{ __html: titelseite.inhalt }}
      />

      {/* Edit Button */}
      {canEdit() && (
        <Box sx={{ marginTop: '40px', textAlign: 'left' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/kurs/${kursId}`)}
            sx={{
              display: isAdminOrDozent ? 'inline-flex' : 'none',
            }}
          >
            Titelseite bearbeiten
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default KursTitelseite;
