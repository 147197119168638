import React, { useState } from 'react';
import { 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Box,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import './EKG_Quiz.css';
import ekgImage from '../assets/EKG_example.png';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function EkgQuiz() {
  const ekgDiagnostik = {
    Rhythmus: {
      Sinusrhythmus: ["Normal", "Sinusarrhythmie", "Sinusbradykardie", "Sinustachykardie", "Sinusarrest", "Sinusknoten-Reentry"],
      "Ektopische Rhythmen": ["Vorhofrhythmus", "Junktionaler Rhythmus", "Ventrikulärer Rhythmus"],
      "Vorhofflimmern/-flattern": ["Vorhofflimmern", "Vorhofflattern (typisch/atypisch)"],
      AVDissoziation: [],
      SchrittmacherRhythmus: [],
    },
    Überleitungsstörungen: {
      AVBlock: ["Grad I", "Grad II Typ Wenckebach (Mobitz I)", "Grad II Typ Mobitz II", "Grad III (kompletter AV-Block)"],
      Schenkelblock: [
        "Rechtsschenkelblock (RSB)",
        "Linksschenkelblock (LSB - inkomplett)",
        "Linksschenkelblock (LSB - komplett)",
      ],
      FasciculärerBlock: ["Linksanteriorer Hemiblock", "Linksposteriorer Hemiblock", "Bifaszikulärer Block", "Trifaszikulärer Block"],
      Präexzitation: ["WPW-Syndrom (Wolff-Parkinson-White)", "LGL-Syndrom (Lown-Ganong-Levine)"],
    },
    Herzachse: ["Normale Herzachse", "Linksabweichung", "Rechtsabweichung", "Indifferenztyp", "Extreme Achsabweichung (Northwest Axis)"],
    Hypertrophien: {
      Linkshypertrophie: ["Sokolow-Lyon-Kriterien", "Cornell-Kriterien"],
      Rechtshypertrophie: [],
      BiatrialeHypertrophie: ["Linksatriale Hypertrophie", "Rechtsatriale Hypertrophie"],
    },
    IschämieUndInfarkt: {
      STHebungen: ["STEMI", "Perikarditis", "Frühe Repolarisation"],
      STSenkungen: ["NSTEMI", "Belastungszeichen"],
      QZacken: ["Pathologische Q-Zacke", "Anterior", "Lateral", "Inferior", "Posterior"],
      TWellenVeränderungen: ["Hyperakut", "Inversionen", "Wellens-Syndrom"],
      RProgression: ["Normal", "Abnorm (z. B. Verlust der R-Progression)"],
    },
    Elektrolytstörungen: {
      Hyperkaliämie: ["Hohe, spitze T-Wellen", "QRS-Verbreiterung"],
      Hypokaliämie: ["Abgeflachte T-Wellen", "U-Wellen"],
      Hyperkalzämie: ["Verkürztes QT-Intervall"],
      Hypokalzämie: ["Verlängertes QT-Intervall"],
      Hypomagnesiämie: ["QT-Verlängerung"],
    },
    Medikamentenwirkungen: {
      DigitalisEffekt: ["Küvetten-förmige ST-Senkung"],
      Antiarrhythmika: ["QT-Verlängerung (z. B. Amiodaron)", "QRS-Verbreiterung"],
      Betablocker: ["Bradykardie"],
      Kalziumkanalblocker: ["Bradykardie"],
    },
    WeiterePathologischeMuster: {
      Perikarditis: ["ST-Hebung in vielen Ableitungen", "PR-Senkung"],
      Lungenembolie: ["S1Q3T3-Muster", "Rechtsherzbelastung (T-Inversionen in V1-V3)"],
      Hypothermie: ["Osborn-Wellen (J-Wellen)"],
      BrugadaSyndrom: ["Typische ST-Hebung in V1-V3"],
      QTVeränderungen: ["Verlängertes QT-Intervall (Long-QT-Syndrom)", "Verkürztes QT-Intervall (Short-QT-Syndrom)"],
    },
    Artefakte: ["Muskelzittern", "Elektrodenfehler", "Wechselstromartefakte"],
    Normvarianten: ["Juveniles T-Wellen-Muster", "Frühe Repolarisation", "Normale Altersveränderungen"],
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [imageOpen, setImageOpen] = useState(false);

  const addItem = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const removeItem = (index) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const clearAll = () => {
    setSelectedItems([]);
  };

  const handleSubmit = () => {
    // TODO: Handle submission
    console.log('Selected items:', selectedItems);
  };

  const renderSubcategories = (subcategories) => {
    if (Array.isArray(subcategories)) {
      // Handle array of items directly
      return subcategories.map((item, i) => (
        <Button 
          key={i}
          variant="text"
          onClick={() => addItem(item)}
          fullWidth
        >
          {item}
        </Button>
      ));
    }

    // Handle object with nested categories
    return Object.entries(subcategories).map(([subcat, items]) => (
      <Box key={subcat}>
        <Typography variant="subtitle1">{subcat}</Typography>
        {Array.isArray(items) && items.length > 0 && items.map((item, i) => (
          <Button 
            key={i}
            variant="text"
            onClick={() => addItem(item)}
            fullWidth
          >
            {item}
          </Button>
        ))}
      </Box>
    ));
  };

  return (
    <Box className="ekg-quiz-container">
      <Typography variant="h4" gutterBottom>
        EKG-Quiz
      </Typography>

      {/* EKG Image */}
      <Paper 
        className="ekg-image-container" 
        onClick={() => setImageOpen(true)}
      >
        <img 
          src={ekgImage} 
          alt="EKG"
          className="ekg-image"
        />
        <IconButton className="zoom-button">
          <ZoomInIcon />
        </IconButton>
      </Paper>

      {/* Selected Items Box */}
      <Paper className="selected-items-box">
        <Typography variant="h6" className="selected-items-title">
          Ausgewählte Elemente
        </Typography>
        <List>
          {selectedItems.map((item, index) => (
            <ListItem 
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => removeItem(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Action Buttons */}
      <Box className="action-buttons">
        <Button 
          variant="contained" 
          color="error" 
          onClick={clearAll}
        >
          Alle löschen
        </Button>
        <Button 
          variant="contained" 
          color="success" 
          onClick={handleSubmit}
        >
          Senden
        </Button>
      </Box>

      {/* Accordion Groups */}
      <Grid container spacing={2}>
        {Object.entries(ekgDiagnostik).map(([category, subcategories], index) => (
          <Grid item xs={12} md={4} key={index}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{category}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderSubcategories(subcategories)}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      {/* Image Zoom Dialog */}
      <Dialog 
        open={imageOpen} 
        onClose={() => setImageOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <IconButton 
          onClick={() => setImageOpen(false)}
          className="dialog-close-button"
        >
          <CloseIcon />
        </IconButton>
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={8}
          wheel={{ step: 0.2 }}
          pinch={{ 
            enabled: true,
            step: 5,
            wheelEnabled: true,
            touchEnabled: true 
          }}
          doubleClick={{ enabled: true, mode: "reset" }}
          panning={{ enabled: true, touchPadEnabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Box className="zoom-controls">
                <IconButton onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </IconButton>
                <IconButton onClick={() => resetTransform()}>
                  <RestartAltIcon />
                </IconButton>
              </Box>
              <TransformComponent>
                <img 
                  src={ekgImage} 
                  alt="EKG"
                  className="ekg-image-full"
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Dialog>
    </Box>
  );
}

export default EkgQuiz;