import React, { useState, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Für Navigation verwenden
import AuthContext from './AuthContext';
import './CourseNavigation.css';

const CourseNavigation = ({ sidebarOpen }) => {
  const [openCourses, setOpenCourses] = useState({});
  const [kurse, setKurse] = useState([]);
  const { token } = useContext(AuthContext); // Token aus dem AuthContext verwenden
  const navigate = useNavigate(); // Verwende useNavigate für das Routing

  // Funktion, um Kurse und Kapitel des Benutzers oder Admins zu laden
  const fetchKurseUndKapitel = async () => {
    try {
      const response = await axios.get('/api/getEigeneKurseUndKapitel', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKurse(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kurse:', error);
    }
  };

  useEffect(() => {
    fetchKurseUndKapitel(); // Lade Kurse und Kapitel, wenn die Komponente gerendert wird
  }, []);

  const handleClick = (courseId) => {
    setOpenCourses((prevState) => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  // Funktion, die aufgerufen wird, wenn auf ein Kapitel geklickt wird
  const handleKapitelClick = (kapitelId) => {
    navigate(`/kapitel/${kapitelId}`); // Navigiere zur Route des Kapitels
  };

  // Dummy-Funktionen für die Navigation
  const handleMeineKurseClick = () => {
    navigate(`/`);
  };

  const handleMeineTermineClick = () => {
    navigate(`/termine`);
  };

  const handleProfilBearbeitenClick = () => {
    navigate(`/profile`);
  };

  return (
    <div className={`course-navigation ${sidebarOpen ? 'open' : ''}`}>
      <h2>Navigation</h2>
      <h3>Kurse</h3>
      <List component="nav">
        {kurse.map((kurs) => (
          <React.Fragment key={kurs.kurs_id}>
            <ListItem button onClick={() => handleClick(kurs.kurs_id)}>
              <ListItemText primary={kurs.name} className="chapter-name" />
              {openCourses[kurs.kurs_id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCourses[kurs.kurs_id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {kurs.kapitel && kurs.kapitel.length > 0 ? (
                  kurs.kapitel.map((kapitel) => (
                    <ListItem
                      button
                      key={kapitel.kapitel_id}
                      className="subchapter-item"
                      onClick={() => handleKapitelClick(kapitel.kapitel_id)} // Auf Kapitel klicken
                    >
                      <ListItemText primary={kapitel.name} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Keine Kapitel verfügbar" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
      <div className="navigation-buttons">
        <Button variant="contained" onClick={handleMeineKurseClick}>
          Meine Kurse
        </Button>
        <Button variant="contained" onClick={handleMeineTermineClick}>
          Meine Termine
        </Button>
        <Button variant="contained" onClick={handleProfilBearbeitenClick}>
          Profil bearbeiten
        </Button>
      </div>
    </div>
  );
};

export default CourseNavigation;
