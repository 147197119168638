import React, { useState } from 'react';
import { Box, Radio, Checkbox, Button, FormControlLabel, Typography } from '@mui/material';
import './Quiz.css';  // Add this import

export function QuizComponent({ data }) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showResult, setShowResult] = useState(false);

  const handleAnswerChange = (index) => {
    if (data.type === 'single') {
      setSelectedAnswers([index]);
    } else {
      setSelectedAnswers(prev => 
        prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
      );
    }
  };

  const getAnswerClass = (index) => {
    if (!showResult) return '';

    // Only show colors for selected answers
    if (!selectedAnswers.includes(index)) return '';

    if (data.type === 'single') {
      // Single choice - simple correct/incorrect
      return data.correctAnswers.includes(index) ? 'answer-correct' : 'answer-incorrect';
    } else {
      // Multiple choice - all selected must be correct, no hints
      const allCorrect = selectedAnswers.every(i => data.correctAnswers.includes(i)) &&
                        data.correctAnswers.every(i => selectedAnswers.includes(i));
      
      return allCorrect ? 'answer-correct' : 'answer-incorrect';
    }
  };

  const resetQuiz = () => {
    setSelectedAnswers([]);
    setShowResult(false);
  };

  return (
    <Box className={`quiz-container ${showResult ? 'show-result' : 'reset'}`}>
      <Typography variant="h6" className="quiz-question">{data.question}</Typography>
      <Box className="quiz-answers">
        {data.answers.map((answer, index) => (
          <FormControlLabel
            key={index}
            className={getAnswerClass(index)}
            control={data.type === 'single' ? (
              <Radio 
                checked={selectedAnswers.includes(index)}
                onChange={() => !showResult && handleAnswerChange(index)}
              />
            ) : (
              <Checkbox
                checked={selectedAnswers.includes(index)}
                onChange={() => !showResult && handleAnswerChange(index)}
              />
            )}
            label={answer.text}
          />
        ))}
      </Box>
      {showResult ? (
        <Button variant="contained" onClick={resetQuiz}>
          Wiederholen
        </Button>
      ) : (
        <Button 
          variant="contained" 
          onClick={() => setShowResult(true)}
          disabled={selectedAnswers.length === 0}
        >
          Überprüfen
        </Button>
      )}
    </Box>
  );
}