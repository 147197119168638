import React, { useState, useContext } from 'react';
import './MainPage.css';
import CourseNavigation from './CourseNavigation';
import Termine from './Termine';
import UserProfile from './UserProfile';
import Register from './Register';
import AdminPage from './AdminPage'; // Import der AdminPage-Komponente
import DozentInPage from './DozentInPage';
import UserPage from './UserPage';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'; // Import Chevron Icons

function MainPage() {
  const { user } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  // Rollen direkt aus user-Objekt lesen
  const isAdmin = user?.isAdmin || false;
  const isDozentIn = user?.isDozentIn || false;

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div id="container">
      <button className={`toggle-button ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </button>
      <nav>
        <CourseNavigation sidebarOpen={sidebarOpen} />
      </nav>
      <main className="main-content">
        <Routes>
          {/* Wenn der Benutzer Admin ist, wird die AdminPage als Startseite verwendet */}
          <Route path="/" element={isAdmin ? <AdminPage /> : isDozentIn ? <DozentInPage /> : <UserPage />} />
          
          {/* Weitere Routen für das Profil und die Registrierung */}
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/register" element={<Register />} />
          
          {/* Platzhalter für untergeordnete Routen */}
          <Route path="/*" element={<Outlet />} /> 
        </Routes>
      </main>
      <aside id="mainpage-termine">
        <Termine />
      </aside>
    </div>
  );
}

export default MainPage;
