import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initial load
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const handleTokenExpiration = () => {
    setUser(null);
    localStorage.removeItem('user');
    setLoading(false);
    window.location.href = '/';
  };

  // Simplified token validation
  const validateToken = async () => {
    try {
      const response = await axios.get('https://doctrina-mobilis.de/api/user', {
        withCredentials: true,
      });
      return response.status === 200 || response.status === 304;
    } catch (error) {
      return false;
    }
  };

  // Periodic token check
  useEffect(() => {
    if (user) {
      const tokenCheck = setInterval(async () => {
        const isValid = await validateToken();
        if (!isValid) {
          handleTokenExpiration();
        }
      }, 60000);
      return () => clearInterval(tokenCheck);
    }
  }, [user]);

  const checkUserRoles = async (userData) => {
    try {
      const [adminResponse, dozentResponse] = await Promise.all([
        axios.get('/api/checkAdmin'),
        axios.get('/api/checkDozentIn')
      ]);
      
      // Deep compare the roles before returning new object
      if (userData.isAdmin !== adminResponse.data.isAdmin || 
          userData.isDozentIn !== dozentResponse.data.isDozentIn ||
          JSON.stringify(userData.dozentKursIds) !== JSON.stringify(dozentResponse.data.dozentKursIds)) {
        return {
          ...userData,
          isAdmin: adminResponse.data.isAdmin,
          isDozentIn: dozentResponse.data.isDozentIn,
          dozentKursIds: dozentResponse.data.dozentKursIds || []
        };
      }
      return userData; // Return existing object if no changes
    } catch (error) {
      console.error('Error checking roles:', error);
      return userData;
    }
  };

  const checkUserStatus = async () => {
    try {
      const response = await axios.get('https://doctrina-mobilis.de/api/user', {
        withCredentials: true,
      });
      if (response.status === 200) {
        const userWithRoles = await checkUserRoles(user || response.data);
        if (userWithRoles !== user) { // Only update if user data changed
          setUser(userWithRoles);
          localStorage.setItem('user', JSON.stringify(userWithRoles));
        }
      } else {
        handleTokenExpiration();
      }
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        handleTokenExpiration();
      }
      console.error('Error checking user:', error);
    }
  };

  const login = async (userData) => {
    try {
      const userWithRoles = await checkUserRoles(userData);
      setUser(userWithRoles);
      localStorage.setItem('user', JSON.stringify(userWithRoles));
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      setUser(null);
      localStorage.removeItem('user');
  
      // Lösche den Token-Cookie
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=Strict;';
  
      axios.post('https://doctrina-mobilis.de/api/logout', {}, { withCredentials: true })
        .then(() => {
          console.log('Logout successful');
          resolve();  // Erfolgreicher 
          window.location.href = '/'; // Force redirect to login
        })
        .catch(error => {
          console.error('Error during logout:', error);
          reject(error);  // Fehler beim Logout
        });
    });
  };
  
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, loading, setLoading, checkUserStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
