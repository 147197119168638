import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails,
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Select, MenuItem, FormControl, InputLabel, Divider, Badge
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import './Nachrichten.css';
import { useAuth } from '../AuthContext';
import axios from 'axios';

function Nachrichten() {
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedType, setSelectedType] = useState('privat');
  const [betreff, setBetreff] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [empfaenger, setEmpfaenger] = useState('');
  const [kurs, setKurs] = useState('');
  const [nachrichten, setNachrichten] = useState([]); // Empty array for now
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableKurse, setAvailableKurse] = useState([]);
  const [availableUsersForKurs, setAvailableUsersForKurs] = useState([]);
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [systemReplyDialog, setSystemReplyDialog] = useState(false);
  const [selectedSystemMessage, setSelectedSystemMessage] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [expandedMessage, setExpandedMessage] = useState(null);
  const [geleseneNachrichten, setGeleseneNachrichten] = useState([]);

  const isOwnMessage = (senderName) => {
    return user?.username === senderName;
  };

  // Update getLatestMessageDate function to track latest activity from others
  const getLatestMessageDate = (nachricht) => {
    const dates = [
      // Only include original message if it's not from current user
      ...(isOwnMessage(nachricht.sender) ? [] : [new Date(nachricht.erstellt_am)]),
      // Include replies from others
      ...(nachricht.antworten
        ?.filter(a => !isOwnMessage(a.sender))
        ?.map(a => new Date(a.erstellt_am)) || [])
    ];
    return dates.length > 0 ? 
      new Date(Math.max(...dates.map(date => date.getTime()))) : 
      new Date(nachricht.erstellt_am);
  };

  // Update sortedNachrichten to properly handle message sorting
  const sortedNachrichten = [...nachrichten].sort((a, b) => {
    const aLatestOtherDate = getLatestMessageDate(a);
    const bLatestOtherDate = getLatestMessageDate(b);

    // If both messages have activity from others, sort by latest activity
    if (!isOwnMessage(a.sender) || !isOwnMessage(b.sender)) {
      return bLatestOtherDate - aLatestOtherDate;
    }

    // Keep original order for own messages
    return nachrichten.indexOf(a) - nachrichten.indexOf(b);
  });

  // Update checkUnreadMessages function
  const checkUnreadMessages = useCallback((messages) => {
    if (!user?.gelesene_nachrichten_ids) return;
    const geleseneIds = user.gelesene_nachrichten_ids;
    let count = 0;
    
    messages.forEach(msg => {
      if (!geleseneIds.includes(msg.nachricht_id)) {
        count++;
      }
    });
    
    setUnreadCount(count);
  }, [user]);

  // Add handler for kurs change
  const handleKursChange = async (event) => {
    const selectedKursId = event.target.value;
    setKurs(selectedKursId);
    setEmpfaenger(''); // Reset empfaenger when kurs changes

    if (selectedKursId && selectedType === 'privat') {
      try {
        const response = await axios.get(`/api/users-by-kurs/${selectedKursId}`);
        setAvailableUsersForKurs(response.data);
      } catch (error) {
        console.error('Fehler beim Laden der User:', error);
      }
    }
  };

  const isFormValid = () => {
    if (!betreff?.trim() || !nachricht?.trim() || !kurs) {
      return false;
    }

    if (selectedType === 'privat' && !empfaenger) {
      return false;
    }

    return true;
  };

  // Update handleSendMessage
  const handleSendMessage = async () => {
    if (!isFormValid()) return;

    try {
      let response;
      const messageData = {
        betreff,
        inhalt: nachricht,
        kurs_id: parseInt(kurs)
      };

      if (selectedType === 'privat') {
        response = await axios.post('/api/send', {
          ...messageData,
          empfaenger_id: parseInt(empfaenger)
        });
      } else if (selectedType === 'rundmail') {
        if (kurs === 'system' && user?.isAdmin) {
          response = await axios.post('/api/sendSystemRoundmail', {
            betreff: messageData.betreff,
            inhalt: messageData.inhalt
          });
        } else {
          response = await axios.post('/api/sendRoundmail', messageData);
        }
      }

      if (response?.status === 201) {
        setBetreff('');
        setNachricht('');
        setEmpfaenger('');
        setKurs('');
        setSelectedType('privat');
        setOpenDialog(false);

        // Refresh messages
        const inboxResponse = await axios.get('/api/inbox');
        setNachrichten(inboxResponse.data);
      }
    } catch (error) {
      console.error('Fehler beim Senden der Nachricht:', error);
    }
  };

  // Update handleReply function
const handleReply = async (nachrichtId) => {
  try {
    const originalMessage = nachrichten.find(n => n.nachricht_id === nachrichtId);
    
    // Handle reply based on message type
    if (originalMessage.typ === 'rundmail') { // Remove kurs_id check to catch all rundmails
      // Rundmail - create new private thread like system messages
      const response = await axios.post('/api/send', {
        betreff: `RE: ${originalMessage.betreff}`,
        inhalt: replyText,
        empfaenger_id: originalMessage.sender_id,
        kurs_id: null,
        typ: 'privat',
        parent_id: null // New thread
      });

      if (response.status === 201) {
        const inboxResponse = await axios.get('/api/inbox');
        setNachrichten(inboxResponse.data);
        setReplyText('');
        setReplyingTo(null);
      }
    } else {
      // Regular private message reply - use existing thread
      const response = await axios.post('/api/send', {
        betreff: originalMessage.betreff,
        inhalt: replyText,
        empfaenger_id: originalMessage.sender_id,
        kurs_id: originalMessage.kurs_id,
        parent_id: nachrichtId, // Keep thread for private messages
        typ: 'privat'
      });

      if (response.status === 201) {
        // Update UI with new reply
        setNachrichten(prev => prev.map(msg => 
          msg.nachricht_id === nachrichtId 
            ? {
                ...msg,
                antworten: [...(msg.antworten || []), {
                  ...response.data,
                  sender: user.username,
                  erstellt_am: new Date().toISOString()
                }]
              }
            : msg
        ));
        setReplyText('');
        setReplyingTo(null);
      }
    }
  } catch (error) {
    console.error('Fehler beim Senden der Antwort:', error);
  }
};

  // Add handler for system message reply
const handleSystemReply = async () => {
  try {
    // Create private message reply to system message
    const response = await axios.post('/api/send', {
      betreff: `RE: ${selectedSystemMessage.betreff}`,
      inhalt: replyText,
      empfaenger_id: selectedSystemMessage.sender_id,
      kurs_id: null,
      parent_id: null, // First reply to system creates new thread
      typ: 'privat'
    });

    if (response.status === 201) {
      setReplyText('');
      setSystemReplyDialog(false);
      setSelectedSystemMessage(null);
      
      const inboxResponse = await axios.get('/api/inbox');
      setNachrichten(inboxResponse.data);
    }
  } catch (error) {
    console.error('Fehler beim Senden der Antwort:', error);
  }
};

  // Aktualisiere handleAccordionChange:
const handleAccordionChange = async (messageId) => {
  setExpandedMessage(prev => (prev === messageId ? null : messageId));

  const msg = nachrichten.find(m => m.nachricht_id === messageId);
  if (!msg) return;

  const replyIds = msg.antworten?.map(r => r.nachricht_id) || [];
  const allThreadIds = [msg.nachricht_id, ...replyIds];
  
  // Count how many messages were unread before marking as read
  const unreadCount = [msg, ...(msg.antworten || [])]
    .filter(m => m.isUnread)
    .length;

  try {
    await axios.put('/api/updateReadStatus', { 
      messageIds: allThreadIds, 
      isRead: true 
    });

    setNachrichten(prev =>
      prev.map(m => {
        if (m.nachricht_id === messageId) {
          return {
            ...m,
            isUnread: false,
            antworten: m.antworten?.map(r => ({ ...r, isUnread: false })) || []
          };
        }
        return m;
      })
    );

    // Update counter with all newly read messages
    if (unreadCount > 0) {
      setUnreadCount(prev => Math.max(0, prev - unreadCount));
    }

  } catch (err) {
    console.error('Fehler beim Aktualisieren:', err);
  }
};

  // Update message type badge colors
  const getMessageTypeColor = (typ, isSystem) => {
    if (typ === 'rundmail') {
      return isSystem ? 'var(--warning-color)' : '#FFA500';
    }
    return 'var(--primary-color)';
  };

  // Add function to handle new messages/replies
  const handleNewMessages = useCallback((newMessages, currentMessages) => {
    if (!currentMessages?.length) return; // Skip initial load
    
    const messagesToUpdate = new Set();
  
    newMessages.forEach(newMsg => {
      const existingMsg = currentMessages.find(msg => msg.nachricht_id === newMsg.nachricht_id);
      
      if (!existingMsg || 
          (existingMsg.antworten?.length !== newMsg.antworten?.length && 
           expandedMessage !== newMsg.nachricht_id)) {
        messagesToUpdate.add(newMsg.nachricht_id);
      }
    });
  
    if (messagesToUpdate.size > 0) {
      axios.put('/api/updateReadStatus', {
        messageIds: Array.from(messagesToUpdate),
        isRead: false
      });
    }
  }, [expandedMessage]);

  // Initial data loading
useEffect(() => {
  const fetchInitialData = async () => {
    try {
      const [inboxResponse, coursesResponse] = await Promise.all([
        axios.get('/api/inbox'),
        axios.get('/api/available-courses')
      ]);
      
      setAvailableKurse(coursesResponse.data);
      setNachrichten(inboxResponse.data);
    } catch (error) {
      console.error('Fehler beim Laden der Initialdaten:', error);
    }
  };
  fetchInitialData();
}, []);

// Single polling effect
useEffect(() => {
  const checkMessages = async () => {
    try {
      const [messagesRes, statusRes] = await Promise.all([
        axios.get('/api/inbox'),
        axios.get('/api/message-status')
      ]);

      // Create unread status map for quick lookups
      const unreadStatusMap = new Map(
        statusRes.data.map(msg => [msg.nachricht_id, msg.isUnread])
      );

      // Update messages with unread status
      const updatedMessages = messagesRes.data.map(msg => {
        // Check if parent message or any reply is unread
        const allMessageIds = [
          msg.nachricht_id,
          ...(msg.antworten?.map(r => r.nachricht_id) || [])
        ];
        const threadHasUnread = allMessageIds.some(id => unreadStatusMap.get(id));

        return {
          ...msg,
          isUnread: threadHasUnread,
          antworten: msg.antworten?.map(reply => ({
            ...reply,
            isUnread: unreadStatusMap.get(reply.nachricht_id) || false
          })) || []
        };
      });

      // Count unread parent messages (threads with unread content)
      const unreadCount = updatedMessages.filter(msg => msg.isUnread).length;

      setNachrichten(updatedMessages);
      setUnreadCount(unreadCount);

    } catch (error) {
      console.error('Fehler beim Aktualisieren:', error);
    }
  };

  checkMessages();
  const interval = setInterval(checkMessages, 30000);
  return () => clearInterval(interval);
}, []);

  return (
    <Container maxWidth="sm">
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: 'var(--primary-color)', color: 'var(--custom-white)' }}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            Nachrichten
          </Typography>
          <Badge 
            badgeContent={unreadCount} 
            color="error"
            sx={{ 
              ml: 2,
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--warning-color)',
                color: 'var(--custom-white)'
              }
            }}
          >
            <MailIcon />
          </Badge>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{ 
              backgroundColor: 'var(--accent-color)',
              margin: 2,
              width: 'calc(100% - 32px)',
              color: 'var(--text-color)'
            }}
          >
            Nachricht schreiben
          </Button>

          <div style={{ 
            maxHeight: '500px', 
            overflowY: 'auto',
            padding: '0 16px'
          }}>
            {sortedNachrichten.map((nachricht) => (
              <Accordion 
                key={nachricht.nachricht_id}
                className="nachricht-accordion"
                sx={{ marginBottom: 1 }}
                expanded={expandedMessage === nachricht.nachricht_id}
                onChange={() => handleAccordionChange(nachricht.nachricht_id)}
              >
                <AccordionSummary 
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          backgroundColor: getMessageTypeColor(nachricht.typ, !nachricht.kurs_id),
                          padding: '2px 8px',
                          borderRadius: '4px',
                          color: 'var(--custom-white)'
                        }}
                      >
                        {nachricht.typ === 'privat' 
                          ? 'Privatnachricht' 
                          : (!nachricht.kurs_id ? 'Systemnachricht' : 'Rundmail')}
                      </Typography>
                      {nachricht.isUnread && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            backgroundColor: 'var(--warning-color)',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            color: 'var(--custom-white)'
                          }}
                        >
                          Neu
                        </Typography>
                      )}
                    </div>
                    <Typography variant="body2" sx={{ marginTop: 1, color: 'var(--text-color)' }}>
                      Von: {nachricht.sender} {nachricht.empfaenger_id && `| An: ${nachricht.empfaenger}`} | Kurs: {nachricht.kurs || 'System'}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'var(--text-color)' }}>
                      {nachricht.betreff}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'var(--text-color)' }}>
                      Letzte Nachricht: {getLatestMessageDate(nachricht).toLocaleString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ 
                  backgroundColor: 'var(--custom-white)',
                  padding: 2
                }}>
                  <div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                  }}>
                    {/* Original message */}
                    <div style={{ 
                      alignSelf: isOwnMessage(nachricht.sender) ? 'flex-end' : 'flex-start',
                      maxWidth: '80%',
                      backgroundColor: isOwnMessage(nachricht.sender) ? 'var(--accent-color)' : '#e8e8e8',
                      padding: '8px 12px',
                      borderRadius: isOwnMessage(nachricht.sender) ? '12px 12px 0 12px' : '12px 12px 12px 0',
                    }}>
                      <Typography sx={{ color: 'var(--text-color)' }}>
                        {nachricht.inhalt}
                      </Typography>
                      <Typography variant="caption" display="block" sx={{ color: 'var(--primary-color)', textAlign: isOwnMessage(nachricht.sender) ? 'right' : 'left' }}>
                        {nachricht.sender} - {nachricht.erstellt_am ? new Date(nachricht.erstellt_am).toLocaleString('de-DE') : ''}
                      </Typography>
                    </div>

                    {/* Replies */}
                    {nachricht.antworten?.map((antwort) => (
                      <div 
                        key={antwort.nachricht_id}
                        style={{ 
                          alignSelf: isOwnMessage(antwort.sender) ? 'flex-end' : 'flex-start',
                          maxWidth: '80%',
                          backgroundColor: isOwnMessage(antwort.sender) ? 'var(--accent-color)' : '#e8e8e8',
                          padding: '8px 12px',
                          borderRadius: isOwnMessage(antwort.sender) ? '12px 12px 0 12px' : '12px 12px 12px 0',
                        }}
                      >
                        <Typography sx={{ color: 'var(--text-color)' }}>
                          {antwort.inhalt}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ color: 'var(--primary-color)', textAlign: isOwnMessage(antwort.sender) ? 'right' : 'left' }}>
                          {antwort.sender} - {new Date(antwort.erstellt_am).toLocaleString('de-DE')}
                        </Typography>
                      </div>
                    ))}
                  </div>

                  <div style={{ marginTop: '16px' }}>
                    <div style={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px'
                    }}>
                      {replyingTo === nachricht.nachricht_id ? (
                        <div className="reply-container">
                          <TextField
                            className="reply-textfield"
                            fullWidth
                            multiline
                            rows={2}
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            placeholder="Ihre Antwort..."
                            size="small"
                          />
                          <Button
                            variant="contained"
                            size="small"
                            disabled={!replyText.trim()}
                            onClick={() => handleReply(nachricht.nachricht_id)}
                            className="reply-button"
                            sx={{ 
                              backgroundColor: 'var(--accept-color)',
                              color: 'var(--custom-white)'
                            }}
                          >
                            Senden
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', gap: '8px' }}>
                          {nachricht.typ === 'rundmail' && !nachricht.kurs_id ? (
                            // System message reply button
                            <Button
                              variant="contained"
                              startIcon={<ReplyIcon />}
                              size="small"
                              onClick={() => {
                                setSelectedSystemMessage(nachricht);
                                setSystemReplyDialog(true);
                              }}
                              sx={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)' }}
                            >
                              Auf Systemnachricht antworten
                            </Button>
                          ) : (
                            // Regular reply and delete buttons for normal messages and course rundmails
                            <>
                              <Button
                                variant="contained"
                                startIcon={<ReplyIcon />}
                                size="small"
                                onClick={() => setReplyingTo(nachricht.nachricht_id)}
                                sx={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)' }}
                              >
                                Antworten
                              </Button>
                              <Button
                                variant="contained"
                                startIcon={<DeleteIcon />}
                                size="small"
                                sx={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)' }}
                              >
                                Löschen
                              </Button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Neue Nachricht</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Typ</InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              label="Typ"
              disabled={!user?.isAdmin && !user?.isDozentIn}
            >
              <MenuItem value="privat">Privatnachricht</MenuItem>
              {(user?.isAdmin || user?.isDozentIn) && (
                <MenuItem value="rundmail">Rundmail</MenuItem>
              )}
            </Select>
          </FormControl>
          
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Kurs</InputLabel>
            <Select
              required
              value={kurs}
              onChange={handleKursChange}
              label="Kurs"
            >
              {selectedType === 'rundmail' && user?.isAdmin && (
                <MenuItem value="system">Systemnachricht</MenuItem>
              )}
              {availableKurse
                .filter(kurs => {
                  if (selectedType === 'privat') {
                    return true; // Show all courses for private messages
                  }
                  // For rundmail
                  if (!user?.isAdmin && !user?.isDozentIn) {
                    return false; // Non-privileged users can't send rundmails
                  }
                  if (user?.isAdmin) {
                    return true; // Admins can send to all courses
                  }
                  // For dozents, only show their courses
                  return user?.dozentKursIds.includes(kurs.kurs_id);
                })
                .map((kurs) => (
                  <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>
                    {kurs.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          
          {selectedType === 'privat' && kurs && (
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel>Empfänger</InputLabel>
              <Select
                required
                value={empfaenger}
                onChange={(e) => setEmpfaenger(e.target.value)}
                label="Empfänger"
              >
                {availableUsersForKurs
                  .sort((a, b) => {
                    if (a.isDozent !== b.isDozent) return b.isDozent - a.isDozent;
                    return a.username.localeCompare(b.username);
                  })
                  .map((user) => (
                    <MenuItem key={user.user_id} value={user.user_id}>
                      {user.isDozent ? (
                        <span style={{ color: 'var(--warning-color)', marginRight: '8px' }}>
                          (Dozent*in)
                        </span>
                      ) : null}
                      {user.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          <TextField
            required
            fullWidth
            label="Betreff"
            value={betreff}
            onChange={(e) => setBetreff(e.target.value)}
            sx={{ marginTop: 2 }}
          />

          <TextField
            required
            fullWidth
            multiline
            rows={4}
            label="Nachricht"
            value={nachricht}
            onChange={(e) => setNachricht(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Abbrechen</Button>
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: isFormValid() ? 'var(--accept-color)' : 'var(--disabled-color)',
              '&:hover': {
                backgroundColor: isFormValid() ? 'var(--accept-color-dark)' : 'var(--disabled-color)'
              }
            }}
            disabled={!isFormValid()}
            onClick={handleSendMessage}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={systemReplyDialog} 
        onClose={() => {
          setSystemReplyDialog(false);
          setSelectedSystemMessage(null);
          setReplyText('');
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Antwort auf Systemnachricht</DialogTitle>
        <DialogContent>
          {selectedSystemMessage && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Original Nachricht
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Betreff: {selectedSystemMessage.betreff}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {selectedSystemMessage.inhalt}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Ihre Antwort"
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                sx={{ mt: 2 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setSystemReplyDialog(false);
            setSelectedSystemMessage(null);
            setReplyText('');
          }}>
            Abbrechen
          </Button>
          <Button
            variant="contained"
            disabled={!replyText.trim()}
            onClick={handleSystemReply}
            sx={{ 
              backgroundColor: 'var(--accept-color)',
              '&:hover': {
                backgroundColor: 'var(--accept-color-dark)'
              }
            }}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Nachrichten;
